import { get, put } from '../../api'
import { CompoundApi } from '../../api-action'
import { responseToBody } from '../../helpers'
import { PostcodeCheckBody, PostcodeCheckResponse, PostcodeValidationBody, PostcodeValidationResponse } from './types'

export const postcodeCheckApi = (
  url: string,
  postcode: string,
): CompoundApi<PostcodeCheckResponse, PostcodeCheckBody> => [
  get(url, { params: { postcode }, timeoutAlert: true }),
  responseToBody,
]

export const postcodeValidationApi = (
  url: string,
  postcode: string,
): CompoundApi<PostcodeValidationResponse, PostcodeValidationBody> => {
  return [put(url, undefined, { params: { postcode } }), responseToBody]
}

import {
  action,
  ActionFactory,
  checkPostcode,
  failureAction,
  FailureActionFactory,
  getAddressesApi,
  Nullable,
  payloadAction,
  PayloadActionFactory,
  Postcode,
  POSTCODE_VALID_SUCCESS,
  PostcodeResult,
  postcodeValidSuccess,
  Suggestion,
  validatePostcode,
} from '@lovejunk/core'
import { PayloadThunk } from 'types/thunk'

import { isPostcodeValid } from './helpers'
import {
  GET_ADDRESSES_FAILURE,
  GET_ADDRESSES_START,
  GET_ADDRESSES_SUCCESS,
  GetAddressesFailure,
  GetAddressesResult,
  GetAddressesStart,
  GetAddressesSuccess,
  SET_POSTCODE,
  SET_POSTCODE_CHECKED,
  SET_POSTCODE_SUPPORTED,
  SET_POSTCODE_VALID,
  SetPostcode,
  SetPostcodeChecked,
  SetPostcodeSupported,
  SetPostcodeValid,
  SUBMIT_POSTCODE,
  SubmitPostcode,
} from './types'

export const getAddressesStart: ActionFactory<GetAddressesStart> = action(GET_ADDRESSES_START)
export const getAddressesSuccess: PayloadActionFactory<GetAddressesSuccess, Suggestion[]> =
  payloadAction(GET_ADDRESSES_SUCCESS)
export const getAddressesFailure: FailureActionFactory<GetAddressesFailure> = failureAction(GET_ADDRESSES_FAILURE)

export const setPostcode: PayloadActionFactory<SetPostcode, Nullable<Postcode>> = payloadAction(SET_POSTCODE)
export const setPostcodeChecked: ActionFactory<SetPostcodeChecked> = action(SET_POSTCODE_CHECKED)
export const setPostcodeSupported: PayloadActionFactory<SetPostcodeSupported, boolean> =
  payloadAction(SET_POSTCODE_SUPPORTED)
export const setPostcodeValid: PayloadActionFactory<SetPostcodeValid, boolean> = payloadAction(SET_POSTCODE_VALID)

export const submitPostcode: ActionFactory<SubmitPostcode> = action(SUBMIT_POSTCODE)

export const getAddresses: PayloadThunk<Postcode, GetAddressesResult> = postcode => dispatch => {
  dispatch(getAddressesStart())

  return getAddressesApi(postcode)
    .then(getAddressesSuccess)
    .catch(getAddressesFailure)
    .then(action => dispatch(action))
}

export const onPostcodeChange: PayloadThunk<Nullable<Postcode>, PostcodeResult> = postcode => dispatch => {
  dispatch(setPostcode(postcode))
  dispatch(setPostcodeChecked())

  if (!isPostcodeValid(postcode)) {
    return Promise.resolve(dispatch(postcodeValidSuccess({ isValid: false })))
  }

  return dispatch(validatePostcode(postcode)).then<PostcodeResult>(action => {
    if (action.type === POSTCODE_VALID_SUCCESS && action.payload.isValid) {
      return dispatch(checkPostcode(postcode))
    }

    return action
  })
}

import { POSTCODE_CHECK_SUCCESS, POSTCODE_VALID_FAILURE, POSTCODE_VALID_SUCCESS } from '@lovejunk/core'
import { flow } from 'lodash'
import Reducer from 'types/reducer'

import {
  addressListLens,
  isPostcodeSupportedLens,
  isPostcodeValidLens,
  isUlezPostcodeLens,
  postcodeLens,
  wasPostcodeCheckedLens,
} from './lens'
import State, { defaultState } from './state'
import {
  GET_ADDRESSES_SUCCESS,
  SET_POSTCODE,
  SET_POSTCODE_CHECKED,
  SET_POSTCODE_SUPPORTED,
  SET_POSTCODE_VALID,
  SUBMIT_POSTCODE,
} from './types'

const reducer: Reducer<State> = (state = defaultState(), action) => {
  switch (action.type) {
    case GET_ADDRESSES_SUCCESS:
      return addressListLens(state).set(action.payload)
    case POSTCODE_CHECK_SUCCESS:
      return flow(
        isPostcodeSupportedLens,
        l => l.set(action.payload.supported),
        isUlezPostcodeLens,
        l => l.set(action.payload.isUlez),
      )(state)
    case SET_POSTCODE_VALID:
      return isPostcodeValidLens(state).set(action.payload)
    case SET_POSTCODE:
      return postcodeLens(state).set(action.payload)
    case SET_POSTCODE_CHECKED:
      return wasPostcodeCheckedLens(state).set(true)
    case SET_POSTCODE_SUPPORTED:
      return isPostcodeSupportedLens(state).set(action.payload)
    case POSTCODE_VALID_FAILURE:
      return isPostcodeValidLens(state).set(false)
    case POSTCODE_VALID_SUCCESS:
      return isPostcodeValidLens(state).set(action.payload.isValid)
    case SUBMIT_POSTCODE:
      return flow(
        isPostcodeValidLens,
        l => l.set(null),
        isPostcodeSupportedLens,
        l => l.set(null),
      )(state)
    default:
      return state
  }
}

export default reducer

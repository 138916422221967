import { action, failureAction, payloadAction } from '../../action-factory'
import apiAction, { ActionBundle } from '../../api-action'
import { ActionFactory, FailureActionFactory, PayloadActionFactory, Postcode } from '../../types/core'
import { PayloadThunk, StatelessPayloadThunk } from '../../types/thunk'
import { withEndpoint } from '../app/helpers'
import { postcodeCheckApi, postcodeValidationApi } from './api'
import {
  POSTCODE_CHECK_FAILURE,
  POSTCODE_CHECK_START,
  POSTCODE_CHECK_SUCCESS,
  POSTCODE_SUPPORTED_FAILURE,
  POSTCODE_SUPPORTED_START,
  POSTCODE_SUPPORTED_SUCCESS,
  POSTCODE_VALID_FAILURE,
  POSTCODE_VALID_START,
  POSTCODE_VALID_SUCCESS,
  POSTCODE_VALIDATION_FAILURE,
  POSTCODE_VALIDATION_START,
  POSTCODE_VALIDATION_SUCCESS,
  PostcodeCheckBody,
  PostcodeCheckFailure,
  PostcodeCheckStart,
  PostcodeCheckSuccess,
  PostcodeSupportedFailure,
  PostcodeSupportedResult,
  PostcodeSupportedStart,
  PostcodeSupportedSuccess,
  PostcodeValidationBody,
  PostcodeValidationFailure,
  PostcodeValidationStart,
  PostcodeValidationSuccess,
  PostcodeValidFailure,
  PostcodeValidResult,
  PostcodeValidStart,
  PostcodeValidSuccess,
  PostcodeValidSuccessPayload,
} from './types'

const postcodeCheckActionBundle: ActionBundle<
  PostcodeCheckStart,
  PostcodeCheckSuccess,
  PostcodeCheckFailure,
  PostcodeCheckBody
> = [action(POSTCODE_CHECK_START), payloadAction(POSTCODE_CHECK_SUCCESS), failureAction(POSTCODE_CHECK_FAILURE)]
export const postcodeSupportedStart: ActionFactory<PostcodeSupportedStart> = action(POSTCODE_SUPPORTED_START)
export const postcodeSupportedSuccess: PayloadActionFactory<PostcodeSupportedSuccess, boolean> =
  payloadAction(POSTCODE_SUPPORTED_SUCCESS)
export const postcodeSupportedFailure: FailureActionFactory<PostcodeSupportedFailure> =
  failureAction(POSTCODE_SUPPORTED_FAILURE)

export const checkPostcode: StatelessPayloadThunk<Postcode, PostcodeSupportedResult> = postcode =>
  withEndpoint(
    'postcodeCheck',
    postcodeCheckUrl => dispatch => {
      dispatch(postcodeSupportedStart())

      return dispatch(apiAction(postcodeCheckActionBundle)(postcodeCheckApi(postcodeCheckUrl, postcode)))
        .then(action =>
          action.type === POSTCODE_CHECK_SUCCESS
            ? postcodeSupportedSuccess(action.payload.supported)
            : postcodeSupportedFailure(action.payload),
        )
        .then(action => dispatch(action))
    },
    postcodeSupportedFailure,
  )

export const postcodeValidStart: ActionFactory<PostcodeValidStart> = action(POSTCODE_VALID_START)
export const postcodeValidSuccess: PayloadActionFactory<PostcodeValidSuccess, PostcodeValidSuccessPayload> =
  payloadAction(POSTCODE_VALID_SUCCESS)
export const postcodeValidFailure: FailureActionFactory<PostcodeValidFailure> = failureAction(POSTCODE_VALID_FAILURE)

const postcodeValidationActionBundle: ActionBundle<
  PostcodeValidationStart,
  PostcodeValidationSuccess,
  PostcodeValidationFailure,
  PostcodeValidationBody
> = [
  action(POSTCODE_VALIDATION_START),
  payloadAction(POSTCODE_VALIDATION_SUCCESS),
  failureAction(POSTCODE_VALIDATION_FAILURE),
]

export const validatePostcode: PayloadThunk<Postcode, PostcodeValidResult> = postcode =>
  withEndpoint(
    'postcodeValidation',
    postcodeValidationUrl => dispatch => {
      dispatch(postcodeValidStart())

      return dispatch(apiAction(postcodeValidationActionBundle)(postcodeValidationApi(postcodeValidationUrl, postcode)))
        .then(action => {
          if (action.type === POSTCODE_VALIDATION_SUCCESS) {
            return postcodeValidSuccess({
              county: action.payload.county,
              isValid: true,
              promoReferralCode: action.payload.promoReferralCode,
            })
          }

          return postcodeValidSuccess({ isValid: false })
        })
        .then(action => dispatch(action))
    },
    postcodeValidFailure,
  )
